import addComponentLoader, { importWithRetry } from '@/utils/component-loader'
import { defineAsyncComponent } from 'vue'


const componentMapping =  {
  ajaxErrorToast: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "ajax-error-toast" */ '@/components/ajax-error-toast'))),
  campaignAssociationField: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "campaign-association-field" */ '@/campaign-form-shared/campaign-association-field'))),
  campaignBlastEmails: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "campaign-blast-emails" */ './campaign-blast-emails/root'))),
  campaignCreatorBlock: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "campaign-creator-block" */ './campaign-creator-block'))),
  contactGroupOrganiser: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "contact-group-organiser" */ '@/local-chapters/contact-organiser.vue'))),
  copyUrlField: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "copy-url-field" */ '@/components/sharing/copy-url'))),
  effortLocationsMap: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "effort-locations-map" */ './efforts/locations-map'))),
  eventShifts: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "event-shifts" */ '@/events/shifts/event-shifts'))),
  groupMembersList: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "group-members-list" */ '@/local-chapters/members-list'))),
  groupsSearchBanner: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "groups-search-banner" */ '@/local-chapters/search-banner'))),
  groupTitleBanner: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "group-title-banner" */ '@/local-chapters/title-banner.vue'))),
  imageUploadAndCrop: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "image-upload-and-crop" */ '@/image/upload-and-crop'))),
  inlineModerationField: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "inline-moderation-field" */ '@/components/inline-moderation-field'))),
  locationAutocomplete: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "location-autocomplete" */ '@/components/inputs/locations/location-autocomplete'))),
  locationSearch: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/components/inputs/locations/location-search'))),
  nationalLocalLocationSelector: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/components/inputs/national-local-location-selector'))),
  partnershipDateFilterableExportBlock: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "partnership-date-filterable-export-block" */ '@/partnerships/date-filterable-export-block'))),
  petitionCarousel: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-carousel" */ '@/petition/carousel'))),
  petitionCategoriesSelector: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-categories-selector" */ '@/components/petition-categories-selector'))),
  petitionFlag: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-flag" */ 'petition/flag'))),
  petitionLaunchCategoriesSetter: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-launch-categories-setter" */ '@/launch-petition/categories-setter'))),
  petitionLaunchImageButton: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-launch-image-button" */ '@/launch-petition/image-button'))),
  petitionLockableInput: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-lockable-input" */ '@/components/petition-lockable-input'))),
  petitionLockableRichText: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-lockable-rich-text" */ '@/components/petition-lockable-rich-text'))),
  settingToggle: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "setting-toggle" */ '@/components/setting-toggle'))),
  shortUrlSetter: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "short-url-setter" */ '@/components/short-url-setter'))),
  socialShareBox: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "social-share-box" */ '@/share/campaign/social-share-box'))),
  trackingFreshchat: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingFreshchat" */ '@/components/tracking/tracking-freshchat'))),
  trackingGoogle: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingGoogle" */ '@/components/tracking/tracking-google'))),
  trackingGtm: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingGtm" */ '@/components/tracking/tracking-gtm'))),
  trackingMatomo: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingMatomo" */ '@/components/tracking/tracking-matomo'))),
  trackingOptimizely: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingOptimizely" */ '@/components/tracking/tracking-optimizely'))),
  trackingPiwikPro: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingPiwikPro" */ '@/components/tracking/tracking-piwik-pro'))),
  trackingSegment: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trackingSegment" */ '@/components/tracking/tracking-segment'))),
  trixTextInput: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "trix-text-input" */ '@/components/inputs/trix-text-input'))),
  userSignInPage: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "user-sign-in-page" */ '@/users/sign-in-page'))),
  userSignUpPage: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "user-sign-up-page" */ '@/users/sign-up-page')))
}

const scriptMapping = {
  campaignNotVisible: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/campaign-form-shared/not-visible')),
  carousel: () => importWithRetry(() => importWithRetry(() => import(/* webpackMode: "lazy" */ './home/carousel'))),
  clickToCopy: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/click-to-copy')),
  collapsibleActions: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/phoenix/collapsible-actions')),
  endCampaign: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/manage-petition/end-campaign')),
  ensureCorrectSegmentUserId: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/analytics/ensure-correct-segment-user-id')),
  formGeoip: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/forms/geoip-country-select')),
  localChapterEventsShow: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/local-chapters/show-events')),
  localChaptersManage: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/local-chapters/manage')),
  namePrivacyNote: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/name-privacy-note')),
  notificationMethods: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/notification-subscriptions')),
  pageViewTracking: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/analytics/page-view-tracking')),
  petitionForm: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/petition/form')),
  petitionShow: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/petition/show')),
  queuedTrackingEvents: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/analytics/queued-tracking-events')),
  recaptcha: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/recaptcha')),
  stackedNavigation: () => importWithRetry(() => import(/* webpackMode: "lazy" */ '@/phoenix/stacked-navigation'))
}

addComponentLoader(componentMapping, scriptMapping)

addComponentLoader({ blastEmailForm: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "blast-email-form" */ '@/blast-email/form'))) },
                   {}, () => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "blast-email-form-store-getter" */ '@/blast-email/store')))

addComponentLoader({ petitionBulkSignatures: defineAsyncComponent(() => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "petition-bulk-signatures" */ '@/petition/bulk-signatures/root'))) },
                   {}, () => importWithRetry(() => import(/* webpackMode: "lazy" */ /* webpackChunkName: "blast-email-form-store-getter" */ '@/petition/bulk-signatures/store')))
